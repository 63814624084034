.socials{
    width: 500px;
    height: fit-content;
    background-color:linear-gradient(#43cea2 , #185a9d);
    border-radius: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: 800px) {

  .socials{

    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .scl{
      margin-left: auto;
      margin-right: auto;
      width:80%;
  }
}

.scButton{

    border: 4px solid white;
   
    
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
    
    width: 90px;
    height: 90px;
    padding:15px;
    border-radius: 30px;
}

.scButton2{

    border: 4px solid white;
   
    
    background-image: linear-gradient(315deg, #36096d 0%, #37d5d6 74%);
    
    width: 90px;
    height: 90px;
    padding:15px;
    border-radius: 30px;
}

#contacts{
  width: 95%;
}