.con{
    height:fit-content;
    width:90%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding-bottom: 20px;
    /* border: 7px #f79341 solid; */
    border-radius: 50px;
}

.port{

    color: black;
    text-align: center;
}

.projectsContainer{

    margin-left: auto;
    margin-right:auto;
    background-color: #f79341;
    width:80%;
    height:fit-content;
    padding-bottom: 90px;
    border-radius: 30px;
    z-index: 10;
}

.display{
    width:100px;
    height:100px;
    z-index: 5;
}

.displayCon{

    margin-left: auto;
    margin-right:auto;
    background-color: #f79341;
    border-radius: 120px;
    width: fit-content;
    padding: 30px;
    top:90px;
    position: relative;
    
}

.displayInbetweenCon{

    margin-left: auto;
    margin-right:auto;
   
    border-radius: 100px;
    border: 4px white solid;
    
    width: fit-content;
    padding: 30px;
    
    position: relative;
}

.displayInnerCon{

    margin-left: auto;
    margin-right:auto;
    background-color: white;
    border-radius: 100px;
    width: fit-content;
    padding: 4px;
    position: relative;
    z-index: 5;
}
#card{

    marginTop:20;
    marginLeft:15; 
    marginRight:1;
}

@media screen and (max-width: 1000px) {

#card{
    
}

.projectsContainer{

    width:100%
}
}

#root1{
    border-top-right-radius: 15px;
    border-bottom-right-radius:15px ;
    margin-right:20px;
    position: relative;
}

.info{
   width:30px;
   height: 30px;
}

.eye{
    width:30px;
    height: 30px;
}

.firstRow{
    width:100%;
    height: fit-content;
    display: flex;
}

.secondRow{
    width:100%;
    height: fit-content;
    display:flex;
    margin-top: 15px;
}

