
.bannerCon{
  height:100%;
  width:100%;
  text-align: center;
}

.banner{
  height:60px;
  width:60%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5672f;
  border-radius: 30px;
}

.bannerInnerCon{
    height:88%;
    width:99%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 3px;
    border-radius: 30px;
    border: 4px white solid;
    color: white;
    text-align: center;
}

.inspiration{
    margin-top: 10px;
    font-family: "Copperplate", fantasy;
}

.team{
    width: 80px;
    height: 80px;
}