.navbar{

  -webkit-box-shadow: 0 8px 6px -6px black;
	   -moz-box-shadow: 0 8px 6px -6px black;
	        box-shadow: 0 8px 6px -6px black;
          width: 100%;
}


.logo{
  font-family: "Copperplate", fantasy;
  margin-top: 10px;
}

.item1 {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
    color: white;
  }



.icon{
  border: 3px solid white;
 
  
  border-radius: 40px ;
}


.items{
  
  background-color: #f5672f;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "Copperplate", fantasy;
}

.items:hover{

  box-shadow: 6px 10px  black;
  bottom:3px;
  position: relative;
}

.items:active{
  box-shadow: 6px 10px  black;
  bottom:6px;
}


.items:focus{
  border: 3px solid white;
  padding-left: 4px;
  padding-right: 4px;
}

.dropdown{
  height: fit-content;
  position: fixed;
  top: 0;
  margin-top: 70px;
  z-index: 5;
  background-color: #f5672f;
  color: white;
  border-radius: 15px;
  align-items: center;
  right: 0;
}

.dropdownItems{
  color: #fff;
    display: flex;
    
    margin-right: 60px;
    text-decoration: none;
    margin-top: 35px;
    cursor: pointer;
}

.dropdownList{
  margin-left: 60px;

}

@media screen and (max-width: 800px) {


  .selfIcon{
    right:0;
  }

}

.navmenu{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}