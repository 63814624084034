.vid {
    position: absolute;
    width: 100%;
    height: 280px;
    z-index: 1;
    background-color: #26afee;
    align-items:center;
}

.devCon{
    position: absolute;
    z-index: 4;
    float: right;
    margin-right: 240px;
    margin-top: 125px;
    width: fit-content;
    height: fit-content;
    color: black;
    right:0;
    border-radius: 15px;
}

.dev{
    width: 80px;
    height:80px;
}


.code{
    
    float: left;
    margin-left: 260px;
    margin-top: 90px;
    width: 50px;
    height:50px;
    color: rgb(0, 0, 0);
    z-index: 4;
}

.wave{
    z-index: 1;
    margin-top: 100px;
}

#water{
    
    z-index: 1;
    color: rgb(44, 138, 247);
    bottom: 0;
    
}

.island{
    width: 165px;
    height: 185px;
    z-index: 4;
    position: absolute;
    margin-top: 80px;
    right: 0;
}

@media screen and (max-width: 800px) {

    .hero-container {
      display:none;
      box-shadow: none;
    }
    .vid{
        height:230px;
        padding-top: 25px;
        display:none;
    }

    #water{
        display: none;
    }

    .wave{
        display: none;

    }

    .island{
        display:none;
    }

    .devCon{
        display: none;
    }

    canvas{
        display:none;
    }

    .code{
        margin-left: 290px;
        margin-top: 20px;
    }
}


canvas{
    
    height: 50px;
    width: 50px;
    z-index: 4;
    right:0;
    top:0;
    position: absolute;
    margin-right: 40px;
    margin-top: 10px;
}



.anim{
    position: absolute;
    z-index: 1;
    border-radius: 30px;  
    width: 20%;
    height: 20%
}

.hero-container {
    
    background-color: white;
    width:100%;
    height: fit-content;
    flex-direction: column;
    
    
    object-fit: contain;
    
}

.logo{
    /*padding-top: 0px;*/
    
    
  }

  .name{
      margin-top: 25px;
      
      color: white;
      z-index: 4;
      text-align: center;
      font-family: "Copperplate", fantasy;
    }


    