.footer{
    height: 35vh;
    width: 100%;
    background-color: #f79341;
    display: flex;
    position: relative;
    bottom: 0;
    z-index: 5;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    text-align: center;
}

.created{
    color:white;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-top: 4px white solid;
    
}



.hv{
    color: white;
    font-family: "Copperplate", fantasy;
}