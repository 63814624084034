.photo{
    height: 25vh;
    width: 25vh;
    border-radius: 20px;
    margin-bottom: 40px; 
    z-index:0;
    
}

.info{
   height: 20vh;
   width: 50vh; 
   position: relative;
   border-radius: 20px;
   background-color: black;
   display: block;
}

.photobox{
   height: 10vh;
   width: 40vh;
   float:left;
}

.aboutcon{
    height: fit-content;
    width: 90%;
    display:flex;
    position: relative;
    padding-bottom: 400px;
    
    /* background-color: black; */
}

.aboutcon2{
    height: 93vh;
    
    display:block;
    position: absolute;
    float: left;
   
    background-color: #f79341;
    z-index: 3;
}

.aboutcon3{
    height: 50vh;
    width: 70%;
    
    position: relative;
    float: left;
    
    
    margin-left: auto;
    margin-right: auto;
}  
@media screen and (max-width: 800px) {
    .main{
        padding-top: 10px;
        display: block;
        padding-bottom: 400px;
    }
    
    .aboutcon{
        padding-bottom: 130px;
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }
    .aboutcon2{

      display:none;

    }

    .aboutcon3{
        height: 50vh;
        width: 90%;
        
        position: relative;
        float: left;
        left:0;
        margin-left: auto;
        margin-right: auto;
        
        
    }  

    .aboutcon4{
        
        padding-left: 70px;
    } 

    .aboutcon5{
        
        padding-left: 70px;
    } 

    .aboutcon6{
        
        padding-left: 70px;
    } 

    .aboutcon7{
        margin-top: 15px;
        position: relative;
    }

}





.aboutcon4{
    height: 10vh;
    width: 100%;
    display:block;
    position: relative;
    float: left;
    left:0;
    margin-left: 10px;
    margin-top: 50px;
    
} 

.aboutcon5{
    height: 10vh;
    width: 100%;
    display:block;
    position: relative;
    float: left;
    left:0;
    margin-left: 10px;
    margin-top: 50px;
    
} 

.aboutcon6{
    height: 10vh;
    width: 100%;
    display:block;
    position: relative;
    float: left;
    left:0;
    margin-left: 10px;
    margin-top: 50px;
    
} 

.aboutcon7{
    width:50%;
    float:left;
}

.main{
    display:flex;
}

@media screen and (max-width: 1000px) {
    .main{
        padding-top: 10px;
        display: block;
        padding-bottom: 410px;
    }

    .aboutcon{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .aboutcon4{
        height: 10vh;
        width: 80%;
        display:block;
        position: relative;
        float: left;
        left:0;
        margin-left: 10px;
        margin-top: 50px;
        
    } 

    .aboutcon5{
        height: 10vh;
        width: 80%;
        display:block;
        position: relative;
        float: left;
        left:0;
        margin-left: 0px;
        margin-top: 10px;
        
    } 

    .aboutcon6{
        height: 10vh;
        width: 80%;
        display:block;
        position: relative;
        float: left;
        left:0;
        margin-left: 0px;
        margin-top: 10px;
        
    } 

    .aboutcon7{
        height: fit-content;
        width: 100%;
        display:block;
        position: relative;
        margin-top: 60px;
        z-index: 3;
    }

    #root2{
      width:100%
    }
}




.new-message {

    display: none;

}

.about1{
    float: right;
    color:#027bb3;
}

.stepper{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#label {
    color: white;
    font-weight: 500;
}

.html{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
}



.css{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

.js{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

.vs{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

.sql{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 10px;
}

.node{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 50px;
}

.react{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

.git{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

.firebase{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 10px;
}

.mongo{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 50px;
}

.heroku{
    width:90px;
    height:90px;
    display:inline;
    clear: both;
    margin-left: 60px;
}

@media screen and (max-width: 1000px) {

    .html{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
    }

    .css{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 15px;
    }

    .js{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 15px;
    }

    .vs{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 15px;
        margin-top: 10px;
    }

    .sql{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 15px;
    }

    .node{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 9px;
    }
    
    .react{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 12px;
    }

    .git{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 11px;
    }

    .firebase{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 10px;
    }
    
    .mongo{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 12px;
    }
    
    .heroku{
        width:40px;
        height:40px;
        display:inline;
        clear: both;
        margin-left: 12px;
    }

    .res{
        width:350px;
        height: 460px;
    }
}












.skills{

    color: black;
    text-align: center;
}

.resume{

    color: black;
    text-align: center;
}

.stepperCon{
    background-color: black;
    padding: 2px;
    border-radius: 20px;
}

.badgeContainer{
    background-color: black;
    padding: 2px;
    border-radius: 20px;
    height:100%;
    margin-top: 20px;
    z-index: 10;
}

.main{
    padding-top: 100px;
}

.innerBorder{
    border: 4px white solid;
    
    border-radius: 15px;
    z-index: 5;
    height:98%;
    width:98%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

#root2{

    border: 7px #f79341 solid;
    border-radius: 15px;
}

#root2:hover {
    box-shadow: 6px 6px 45px 5px black;
}

#root1:hover {
  box-shadow: 6px 6px 45px 5px black;
}

.learnMore{
    background-color:#fecfa8 ;
    padding: 6px;
   
    border: 4px #f79341 solid;
    border-radius: 15px;
}